<template>
  <van-popup
    :key="Math.random()"
    v-model:show="odialogVisible"
    overlay-class="fullscreen-style"
    position="right"
    teleport="body"
  >
    <!-- <header class="advanced-search">
      <p class="advanced-search-left">{{ title }}</p>
      <p class="advanced-close-right" @click="closeDialog">
        <van-icon name="cross" />
      </p>
    </header> -->
    <div class="dialog-content">
      <slot></slot>
    </div>
    <slot name="footer"></slot>
  </van-popup>
</template>

<script>
import { defineComponent, watch, toRefs } from "vue";

// interface DialogProps {
//   dialogVisible: boolean
//   title: string
// }
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    title: String
  },
  emits: ["update:dialogVisible", "cancel"],
  setup(props, { emit, root }) {
    const { dialogVisible } = toRefs(props);
    const odialogVisible = dialogVisible;
    function closeDialog() {
      changeDialogVisible(false);
    }
    function changeDialogVisible(dialogVisible) {
      emit("update:dialogVisible", dialogVisible); // previously was `this.$emit('input', title)`
      emit("cancel");
    }

    return {
      odialogVisible,
      closeDialog
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.van-col.van-col--8) {
  font-size: 13px;
}
</style>
