import { getMenuList } from "@/http/request/authority";

import router from "@/router/index";
import { recursionRouter } from "@/utils/recursion-router";

export default {
  namespaced: true,
  state: {
    permissionList: null /** 所有路由 */,
    sidebarMenu: [] /** 导航菜单 */,
    // currentMenu: '' /** 当前active导航菜单 */,
    // control_list: [] /** 完整的权限列表 */,
  },
  getters: {},
  mutations: {
    SET_PERMISSION(state, routes) {
      state.permissionList = routes;
    },
    CLEAR_PERMISSION(state) {
      state.permissionList = null;
    },
    SET_MENU(state, menu) {
      state.sidebarMenu = menu;
    },
    CLEAR_MENU(state) {
      state.sidebarMenu = [];
    },
    // SET_CURRENT_MENU(state, currentMenu) {
    //   state.currentMenu = currentMenu
    // },
    // SET_CONTROL_LIST(state, list) {
    //   state.control_list = list
    // },
  },
  actions: {
    async FETCH_PERMISSION({ commit, state }) {
      // 从后台获取的menu
      const permissionList = await getMenuList({});
      console.log("permissionList: ", permissionList);
      // 前端的应用导航 从前端异步路由中选出和后台menu能对应的部分
      if (
        permissionList &&
        permissionList.result &&
        permissionList.result.data
      ) {
        const permissions = permissionList.result.data;
        //路由过滤
        function filterRouter(menu) {
          return menu
            .map((item) => {
              if (
                item.isMobile &&
                (item.isMobile == "0" || item.isMobile == "2")
              ) {
                if (item && item.systemMenuDtoList) {
                  item.systemMenuDtoList =
                    filterRouter(item.systemMenuDtoList) || null;
                }
                return item;
              }
            })
            .filter((item) => item != undefined);
        }
        let newRouter = filterRouter(permissions);
        commit("SET_PERMISSION", newRouter);
        commit("SET_MENU", menu);
      }
    },
  },
};
