import BaseRequest from "@/http/newRequest.js";
const api = new BaseRequest("");
export function dictionaryGetList(params) {
  return api.request({
    url: "dict/item/list",
    method: "post",
    params,
    type: "query",
  });
}
