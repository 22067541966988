import { dictionaryGetList } from "@/http/request/dictionary";
// import { getTreeData } from '@/utils'
// import {
//   clientArchiveGetList,
//   customerContractList,
//   payProtocolList,
//   supplierRecordFindAll,
//   findSupplierAllByCodeOrName,
//   supplierContractList,
//   advertiserRecordList,
//   customerRecordCompanyList
// } from '@/http/request/businessArchives'

// 数据字典中对应的编码，开发时是自己建的，上线后编码根据真实数据做变更
const dicCodeList = {
  CUSTOMER_TYPE: "KHLB", // 客户类别
  TRAFFIC_PLATFORM: "LLPT", // 流量平台
  OFFER_TYPE: "OFLX", // offer类型
  PAY_TYPE: "FKFS", // 付款方式
  FEE_BEARER_TYPE: "SXFCDF", // 手续费承担方
  PAYMENT_DAYS: "HTZQ", // 合同账期
  WHITE_LIST_REASON: "WHITE_LIST_REASON", //白名单原因
  RELEASE_LIST_REASON: "PASS_REASON", //例外放行原因
  RECEIVABLE_STATUS: "FK_YSZT", // 应收状态
  COLLECTION_STAGE: "FK_SKJD", // 收款阶段
  CALL_RESULT: "FK_GTJG", //沟通结果
  CURRENCIES: "BZ", // 币种
};
export default {
  namespaced: true,
  state: {
    customerTypes: [],
    trafficPlatforms: [],
    offerTypes: [], // offer类型
    payTypes: [], // 付款方式
    feeBearerTypes: [], // 手续费承担方
    paymentDays: [], //合同账期
    whiteListReasons: [], //白名单原因
    receivableStatus: [], //应收状态
    collectionStage: [], // 收款阶段
    callResult: [], // 沟通结果
  },
  mutations: {
    SET_COMSTOMER_TYPES: (state, customerTypes) => {
      state.customerTypes = customerTypes;
    },
    SET_TRAFFIC_PLATFORM: (state, plats) => {
      state.trafficPlatforms = plats;
    },
    SET_OFFER_TYPES: (state, types) => {
      state.offerTypes = types;
    },
    SET_PAY_TYPES: (state, types) => {
      state.payTypes = types;
    },
    SET_FEE_BEARER_TYPES: (state, types) => {
      state.feeBearerTypes = types;
    },
    SET_PAYMENT_DAYS: (state, types) => {
      state.paymentDays = types;
    },
    SET_WHITE_LIST_REASON: (state, types) => {
      types.forEach((e) => {
        e.value = e.code;
        e.label = e.zhCn;
      });
      state.whiteListReasons = types;
    },
    SET_RELEASE_REASON: (state, types) => {
      types.forEach((e) => {
        e.value = e.code;
        e.label = e.zhCn;
      });
      state.releaseReasons = types;
    },
    SET_RECEIVABLE_STATUS: (state, receivableStatus) => {
      state.receivableStatus = receivableStatus;
    },
    SET_COLLECTION_STAGE: (state, collectionStage) => {
      state.collectionStage = collectionStage;
    },
    SET_CALL_RESULT: (state, callResult) => {
      state.callResult = callResult;
    },
    SET_CURRENCIES: (state, currencies) => {
      state.currencies = currencies;
    },
  },
  actions: {
    async getTrafficPlatForms({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.TRAFFIC_PLATFORM,
        status: 1,
      });
      commit("SET_TRAFFIC_PLATFORM", result.data);
    },
    async getOfferTypes({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.OFFER_TYPE,
      });
      commit("SET_OFFER_TYPES", result.data);
    },
    async getPayTypes({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.PAY_TYPE,
      });
      commit("SET_PAY_TYPES", result.data);
    },
    async getFeeBearerTypes({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.FEE_BEARER_TYPE,
      });
      commit("SET_FEE_BEARER_TYPES", result.data);
    },
    async getPaymentDays({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.PAYMENT_DAYS,
      });
      commit("SET_PAYMENT_DAYS", result.data);
    },
    async getWhiteListReasons({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.WHITE_LIST_REASON,
      });
      commit("SET_WHITE_LIST_REASON", result.data);
    },
    async getReleaseListReasons({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.RELEASE_LIST_REASON,
        // status: 1,
      });
      commit("SET_RELEASE_REASON", result.data);
    },
    async getReceivableStatus({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.RECEIVABLE_STATUS,
      });
      commit("SET_RECEIVABLE_STATUS", result.data);
    },
    async getCollectionStage({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.COLLECTION_STAGE,
      });
      commit("SET_COLLECTION_STAGE", result.data);
    },
    async getCallResult({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.CALL_RESULT,
      });
      commit("SET_CALL_RESULT", result.data);
    },
    async getCurrencies({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.CURRENCIES,
        status: 1,
      });
      commit("SET_CURRENCIES", result.data);
    },
    async getCallResult({ commit }) {
      const { result } = await dictionaryGetList({
        typeCode: dicCodeList.CALL_RESULT,
      });
      commit("SET_CALL_RESULT", result.data);
    },

    // async getCustomerTypes({ commit }) {
    //   const { result } = await dictionaryGetList({
    //     typeCode: dicCodeList.CUSTOMER_TYPE,
    //     status: 1
    //   })
    //   commit('SET_COMSTOMER_TYPES', result.data)
    // },
  },
};
