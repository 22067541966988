// 注册组件

import Icon from "./svgicon";
import NoData from "./noData";
import Dialog from "./dialog";
import MainHeader from "./mainHeader";

export default {
  Icon,
  NoData,
  MainHeader,
  Dialog,
};
