// 工具类库 纯逻辑和通用业务方法
// 时间格式化方法 可接受时间对象，精确到秒或毫秒的时间戳，第二个参数为时间格式字符串， y年份 m月份 d日期 h小时 i分钟 s秒 a周，最终返回经过格式化后的时间字符串
import accounting from "accounting-js";
import _ from "lodash";
export function formatNumFixed(number = 0, fixedNumber = 2) {
  const str = number === 0 || number === "0" ? "0.00" : number;
  const isNumber = Number(number);
  if (!isNumber) {
    return str;
  }
  if (isNumber.toFixed) {
    return accounting.formatMoney(isNumber, {
      symbol: "",
      precision: fixedNumber,
      thousand: ",",
    });
  }
}
export function numFormat(num) {
  num = String(Number(num).toFixed(2));
  var re = /(-?\d+)(\d{3})/;
  while (re.test(num)) {
    num = num.replace(re, "$1,$2");
  }
  return num;
}
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) {
      time = parseInt(time) * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s)+}/g, (result, key) => {
    let value = formatObj[key];
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

// 从地址栏获取所传递的参数
export function getQuery(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf("?") + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * 获取字节长度，一个汉字算两个字节，数字和字母算一个字节
 * @param {Sting} val input value
 * @returns {number} output value
 */
export function getBLen(val) {
  let len = 0;
  for (let i = 0; i < val.length; i++) {
    if (val[i].match(/[^\x00-\xff]/gi) != null) {
      len += 2;
    } else {
      len += 1;
    }
  }
  return Math.floor(len);
}
// 干净的数组，去除所有为'' || null || false || underfind 等值, includ可以设置需要运行的空项类型,返回干净的数组
export function cleanArray(actual, includ) {
  const newArray = [];
  includ = includ || [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i] || includ.indexOf(actual[i]) > -1) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}
// 提取html字符串里面的内容文本
export function html2Text(val) {
  const div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}
// 对象合并，第一个参数为merge目标对象，第二个为将要merge的对象，可进行多级合并
export function objectMerge(target, source) {
  if (typeof target !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}
// 缓慢滚动动画，element 滚动对象 to 目标位置 duration时间间隔
export function scrollTo(element, to, duration) {
  if (duration <= 0) {
    return;
  }
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;
  setTimeout(() => {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) {
      return;
    }
    scrollTo(element, to, duration - 10);
  }, 10);
}
// 延时器，func 延时函数、 wait等待的时间间隔毫秒、 immediate 第一次调用是否立即生效
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) {
          context = args = null;
        }
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) {
      timeout = setTimeout(later, wait);
    }
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}
// 打开模态窗口 url 需要打开的页面地址  title新窗口的名称 w新窗口的宽度 h新窗口的高度 会居中显示
export function openWindow(url, title, w, h) {
  // Fixes dual-screen position                            Most browsers       Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : screen.top;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}
// 合法性验证库
import rule from "./validate";
export function regs(str, is) {
  console.log("str, is:666 ", str, is, rule[is].test(str));
  return rule[is].test(str);
}
// 获取cookie
export function getCookie(k) {
  const name = k + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export function toThousands(num) {
  const result = [];
  let counter = 0;
  num = (num || 0).toString().split("");
  for (let i = num.length - 1; i >= 0; i--) {
    counter++;
    result.unshift(num[i]);
    if (!(counter % 3) && i != 0) {
      result.unshift(",");
    }
  }
  return result.join("");
}

// 级联选择器处理数据
export const getTreeData = (data) => {
  if (!data) {
    return {};
  }
  data.map((d) => {
    !d.children || d.children.length < 1
      ? (d.children = undefined)
      : getTreeData(d.children);
  });
  return data;
};

// 处理后端返回的时间字符串 2020-03-01 12:41:59

export const formatTimeStr = (str) => {
  const arr = str.split(" ");
  // arr[1] = arr[1].substring(0, 8)
  return arr[0];
};
export const getFormatTimeStr = (str, index) => {
  const arr = str.split(" ");
  // arr[1] = arr[1].substring(0, 8)
  return arr[index];
};
export const getFileJson = (filePath) => {
  let fileList = [];
  const result = [];
  filePath ? (fileList = filePath.split("\u0001")) : null;
  fileList.map((file, index) => {
    file = file.split("/");
    result.push({
      name: file.pop(),
      url: fileList[index],
    });
  });
  return result;
};
export const fromUrlGetFileName = (filePath) => {
  return decodeURI(filePath.split("?")[0].split("/").pop());
};

export function getPreMonth(date, delimiter = "/") {
  const arr = date.split(delimiter);
  const year = arr[0]; //获取当前日期的年份
  const month = arr[1]; //获取当前日期的月份
  const day = arr[2]; //获取当前日期的日
  let days = new Date(year, month, 0);
  days = days.getDate(); //获取当前日期中月的天数
  let year2 = year;
  let month2 = parseInt(month) - 1;
  if (month2 == 0) {
    year2 = parseInt(year2) - 1;
    month2 = 12;
  }
  let day2 = day;
  let days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = "0" + month2;
  }
  const t2 = year2 + delimiter + month2 + delimiter + day2;
  return t2;
}
export function handleFile(arr) {
  arr.forEach((item) => {
    if (item.filePath) {
      let files = item.filePath.split("\u0001");
      files = files.filter((item) => {
        if (item != "") {
          return item;
        }
      });
      item.filePath = files.map((item) => {
        const lastPipe = item.split("?")[0].lastIndexOf("/");
        return {
          name: item.split("?")[0].substr(lastPipe + 1),
          url: item,
        };
      });
    } else {
      item.filePath = [];
    }
  });
  return arr;
}

// 对数组中的某一项求和处理(并有可能会处理汇率)
export function getSummaries(keyName, arr, isUsdRateFlag = true) {
  let sums = 0;
  if (arr && arr.length > 0) {
    const values = arr.map((item) => {
      let usdRate = 1;
      if (isUsdRateFlag) {
        usdRate = item.usdRate ? item.usdRate : 1;
      }
      if (!isNaN(accounting.unformat(item[keyName]))) {
        return Number(
          accMultiply(
            parseFloat(usdRate),
            accounting.unformat(item[keyName]),
            2
          )
        );
      }
    });
    const ovalue = values.reduce((prev, curr) => {
      const value = Number(curr);
      if (!isNaN(value)) {
        return prev + curr;
      } else {
        return prev;
      }
    }, 0);
    // 对求和后的值进行处理
    sums = formatNumFixed(ovalue);
  } else {
    sums = 0;
  }
  return sums;
}
// 两数相乘
export function accMultiply(arg1, arg2, fix) {
  if (!parseInt(fix) == fix) {
    return;
  }
  const num1 = arg1;
  const num2 = arg2;
  const s1 = num1.toString();
  const s2 = num2.toString();

  return accounting.unformat(Number(s1) * Number(s2), 2);
}
// 设置cookie
export function setCookie(c_name, value, expiredays) {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    ";expires=" +
    exdate.toGMTString() +
    ";path=/";
}
//下载文件
export function downloadFile(ourl, fileName = "") {
  // console.log(fileName)
  if (ourl) {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = ourl;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}

export function getFile(url, type = 1) {
  if (!url) {
    return [];
  }
  let cur = [];
  let curNext = [];
  cur = curNext = (url && url.split("\u0001")) || [];
  if (!cur[cur.length - 1]) {
    cur.pop();
  }
  curNext = cur.map((item) => {
    return {
      name: fromUrlGetFileName(item),
      url: item,
    };
  });
  return curNext;
}
export function formatBusinessMonth(str) {
  if (str) {
    const strs = str.substring(5);
    const a = strs.split("~")[1].substring(5);
    return `${strs.split("~")[0]}~${a}`;
  } else {
    return "";
  }
}
export function getSectionTime(time, timeIndex = 0) {
  if (time) {
    return time.split(" ")[timeIndex];
  } else {
    return "";
  }
}
// 数据字典已经code 获取name的方法
export function getDictionaryName(arr, code) {
  if (arr && arr.length > 0 && code) {
    const oitem = arr.find((item) => item.code === code);
    return oitem ? oitem.zhCn : "";
  } else {
    return "";
  }
}
export function getFormaetMoney(number = 0, fixedNumber = 2) {
  const str = number === 0 || number === "0" ? 0 : number;
  if (str === 0) {
    return "0.00";
  }
  const isNumber = Number(number);
  if (!isNumber) {
    return str;
  }
  if (isNumber.toFixed) {
    const str = isNumber + "";
    const isInteger = !(str.indexOf(".") > -1);
    // const Num = isNumber.modifyToFixed(fixedNumber) + "";
    const Num = modifyToFixed(isNumber, fixedNumber) + "";
    //
    const decimals = Num.split(".")[1];
    const integer = Num.split(".")[0];
    const Num_sum = integer.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    const val = isInteger ? Num_sum : Num_sum + "." + decimals;
    // return Num_sum + '.' + decimals;
    return accounting.formatMoney(isNumber, {
      symbol: "",
      precision: fixedNumber,
      thousand: ",",
    });
  }
}
// js toFixed方法精度丢失问题
export function modifyToFixed(num, s) {
  let that = this;
  let changenum;
  let index;
  // 负数
  if (this < 0) {
    that = -that;
  }

  changenum = (
    parseInt(num * Math.pow(10, s) + 0.5) / Math.pow(10, s)
  ).toString();

  index = changenum.indexOf(".");

  if (index < 0 && s > 0) {
    changenum = changenum + ".";

    for (let i = 0; i < s; i++) {
      changenum = changenum + "0";
    }
  } else {
    index = changenum.length - index;
    for (let i = 0; i < s - index + 1; i++) {
      changenum = changenum + "0";
    }
  }

  if (this < 0) {
    return -changenum;
  } else {
    return changenum;
  }
}
export function fmatDay(day) {
  if (!day) {
    return "";
  }
  const date =
    day > 0 ? `剩余 ${day}` : `逾期 ${day.toString().replace("-", "")}`;
  return `${date} 天`;
}

export function formatDate(timestamp, dot = "/") {
  console.log("timestamp: ", timestamp);
  var date = new Date(timestamp);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return (
    year +
    dot +
    (month > 9 ? month : "0" + month) +
    dot +
    (day > 9 ? day : "0" + day)
  );
}
export function notAllowedEmpty(params) {
  for (const key of Object.keys(params)) {
    if (
      (_.isArray(params[key]) && params[key].length === 0) ||
      params[key] === "" ||
      params[key] === null
    ) {
      delete params[key];
    }
  }
  return params;
}
export const nowDate = (value) => {
  const year = value.getFullYear();
  const month =
    value.getMonth() > 8 ? value.getMonth() + 1 : "0" + (value.getMonth() + 1);
  const day = value.getDate() > 9 ? value.getDate() : "0" + value.getDate();
  return {
    time: `${year}年${month}月${day}日`,
    value: `${year}${month}`,
    year: year,
  };
};
export const check_support_webp = () => {
  return (
    document
      .createElement("canvas")
      .toDataURL("image/webp")
      .indexOf("data:image/webp") == 0
  );
};

export function getNameByUrl(url) {
  if (!url) {
    return;
  }
  let arr = url.split("?AccessKeyId")[0].split("/");
  let name = arr[arr.length - 1];
  name = decodeURIComponent(name);
  return name;
}

export function CloseWebPage() {
  // 窗口关闭
  if (
    navigator.userAgent.indexOf("MSIE") > 0 ||
    navigator.userAgent.indexOf("Feishu") > 0
  ) {
    //. ie浏览器，飞书
    window.opener = null;
    window.close();
  } else if (navigator.userAgent.indexOf("WeChat") > 0) {
    // 微信
    WeixinJSBridge.invoke("closeWindow", {}, function (res) {});
  } else {
    //谷歌等其他浏览器
    window.opener = null;
    window.location.replace("about:blank");
  }
  sessionStorage.clear();
  localStorage.clear();
}

export const proList = [
  "北京市",
  "天津市",
  "河北省",
  "山西省",
  "内蒙古自治区",
  "辽宁省",
  "吉林省",
  "黑龙江省",
  "上海市",
  "江苏省",
  "浙江省",
  "安徽省",
  "福建省",
  "江西省",
  "山东省",
  "河南省",
  "湖北省",
  "湖南省",
  "广东省",
  "广西壮族自治区",
  "海南省",
  "重庆市",
  "四川省",
  "贵州省",
  "云南省",
  "西藏自治区",
  "陕西省",
  "甘肃省",
  "青海省",
  "宁夏回族自治区",
  "新疆维吾尔自治区",
  "台湾省",
  "香港特别行政区",
  "澳门特别行政区",
];
export const countryPhoneCode = [
  {
    city: "中国大陆",
    code: "+86",
  },
  {
    city: "中国香港",
    code: "+852",
  },
  {
    city: "中国澳门",
    code: "+853",
  },
  {
    city: "中国台湾",
    code: "+886",
  },
  {
    city: "新加坡",
    code: "+65",
  },
  {
    city: "阿富汗",
    code: "+93",
  },
  {
    city: "阿尔巴尼亚",
    code: "+355",
  },
  {
    city: "阿尔格拉",
    code: "+213",
  },
  {
    city: "安道尔",
    code: "+376",
  },
  {
    city: "安哥拉",
    code: "+244",
  },
  {
    city: "安圭拉",
    code: "+1264",
  },
  {
    city: "阿森松岛",
    code: "+247",
  },
  {
    city: "安提瓜和巴布达",
    code: "+1268",
  },
  {
    city: "阿根廷",
    code: "+54",
  },
  {
    city: "亚美尼亚",
    code: "+374",
  },
  {
    city: "阿鲁巴",
    code: "+297",
  },
  {
    city: "澳大利亚",
    code: "+61",
  },
  {
    city: "奥地利",
    code: "+43",
  },
  {
    city: "阿塞拜疆",
    code: "+994",
  },
  {
    city: "巴哈马",
    code: "+1242",
  },
  {
    city: "巴林",
    code: "+973",
  },
  {
    city: "孟加拉国",
    code: "+880",
  },
  {
    city: "巴巴多斯",
    code: "+1246",
  },
  {
    city: "白俄罗斯",
    code: "+375",
  },
  {
    city: "比利时",
    code: "+32",
  },
  {
    city: "伯利兹",
    code: "+501",
  },
  {
    city: "贝宁",
    code: "+229",
  },
  {
    city: "百慕大",
    code: "+1441",
  },
  {
    city: "不丹",
    code: "+975",
  },
  {
    city: "玻利维亚",
    code: "+591",
  },
  {
    city: "波斯尼亚和黑塞哥维那",
    code: "+387",
  },
  {
    city: "博茨瓦纳",
    code: "+267",
  },
  {
    city: "巴西",
    code: "+55",
  },
  {
    city: "文莱",
    code: "+673",
  },
  {
    city: "保加利亚",
    code: "+359",
  },
  {
    city: "布基纳法索",
    code: "+226",
  },
  {
    city: "布隆迪",
    code: "+257",
  },
  {
    city: "柬埔寨",
    code: "+855",
  },
  {
    city: "喀麦隆",
    code: "+237",
  },
  {
    city: "加拿大",
    code: "+1",
  },
  {
    city: "佛得角",
    code: "+238",
  },
  {
    city: "开曼群岛",
    code: "+1345",
  },
  {
    city: "中非共和国",
    code: "+236",
  },
  {
    city: "乍得",
    code: "+235",
  },
  {
    city: "智利",
    code: "+56",
  },
  {
    city: "哥伦比亚",
    code: "+57",
  },
  {
    city: "科摩罗",
    code: "+269",
  },
  {
    city: "刚果共和国",
    code: "+242",
  },
  {
    city: "刚果民主共和国",
    code: "+243",
  },
  {
    city: "库克群岛",
    code: "+682",
  },
  {
    city: "哥斯达黎加",
    code: "+506",
  },
  {
    city: "科特迪沃",
    code: "+225",
  },
  {
    city: "克罗地亚",
    code: "+385",
  },
  {
    city: "古巴",
    code: "+53",
  },
  {
    city: "塞浦路斯",
    code: "+357",
  },
  {
    city: "+捷克共和国",
    code: "+420",
  },
  {
    city: "丹麦",
    code: "+45",
  },
  {
    city: "吉布提",
    code: "+253",
  },
  {
    city: "多米尼加",
    code: "+1767",
  },
  {
    city: "多米尼加共和国",
    code: "+1809",
  },
  {
    city: "厄瓜多尔",
    code: "+593",
  },
  {
    city: "埃及",
    code: "+20",
  },
  {
    city: "艾萨尔瓦多",
    code: "+503",
  },
  {
    city: "爱沙尼亚",
    code: "+372",
  },
  {
    city: "埃塞俄比亚",
    code: "+251",
  },
  {
    city: "法罗群岛",
    code: "+298",
  },
  {
    city: "斐济",
    code: "+679",
  },
  {
    city: "芬兰",
    code: "+358",
  },
  {
    city: "法国",
    code: "+33",
  },
  {
    city: "法属圭亚那",
    code: "+594",
  },
  {
    city: "法属波利尼西亚",
    code: "+689",
  },
  {
    city: "加蓬",
    code: "+241",
  },
  {
    city: "冈比亚",
    code: "+220",
  },
  {
    city: "格鲁吉亚",
    code: "+995",
  },
  {
    city: "德国",
    code: "+94",
  },
  {
    city: "加纳",
    code: "+233",
  },
  {
    city: "直布罗陀",
    code: "+350",
  },
  {
    city: "希腊",
    code: "+30",
  },
  {
    city: "格陵兰",
    code: "+299",
  },
  {
    city: "格林纳达",
    code: "+1473",
  },
  {
    city: "瓜德罗普",
    code: "+590",
  },
  {
    city: "关岛",
    code: "+1671",
  },
  {
    city: "危地马拉",
    code: "+502",
  },
  {
    city: "几内亚",
    code: "+240",
  },
  {
    city: "根西",
    code: "+44",
  },
  {
    city: "几内亚",
    code: "+224",
  },
  {
    city: "圭亚那",
    code: "+592",
  },
  {
    city: "海地",
    code: "+509",
  },
  {
    city: "洪都拉斯",
    code: "+504",
  },
  {
    city: "缅甸",
    code: "+95",
  },
  {
    city: "匈牙利",
    code: "+36",
  },
  {
    city: "冰岛",
    code: "+354",
  },
  {
    city: "印度",
    code: "+91",
  },
  {
    city: "印度尼西亚",
    code: "+62",
  },
  {
    city: "伊朗",
    code: "+98",
  },
  {
    city: "伊拉克",
    code: "+964",
  },
  {
    city: "爱尔兰",
    code: "+353",
  },
  {
    city: "马恩岛",
    code: "+44",
  },
  {
    city: "以色列",
    code: "+972",
  },
  {
    city: "意大利",
    code: "+93",
  },
  {
    city: "牙买加",
    code: "+1876",
  },
  {
    city: "日本",
    code: "+81",
  },
  {
    city: "泽西岛",
    code: "+44",
  },
  {
    city: "约旦",
    code: "+962",
  },
  {
    city: "哈萨克斯坦",
    code: "+7",
  },
  {
    city: "肯尼亚",
    code: "+254",
  },
  {
    city: "科索沃",
    code: "+383",
  },
  {
    city: "科威特",
    code: "+965",
  },
  {
    city: "吉尔吉斯斯坦",
    code: "+996",
  },
  {
    city: "老挝",
    code: "+856",
  },
  {
    city: "拉脱维亚",
    code: "+371",
  },
  {
    city: "黎巴嫩",
    code: "+961",
  },
  {
    city: "莱索托",
    code: "+266",
  },
  {
    city: "利比里亚",
    code: "+231",
  },
  {
    city: "利比亚",
    code: "+218",
  },
  {
    city: "列支敦士登",
    code: "+423",
  },
  {
    city: "立陶宛",
    code: "+370",
  },
  {
    city: "卢森堡",
    code: "+352",
  },
  {
    city: "马其顿",
    code: "+389",
  },
  {
    city: "马达加斯加",
    code: "+261",
  },
  {
    city: "马拉维",
    code: "+265",
  },
  {
    city: "马来西亚",
    code: "+60",
  },
  {
    city: "马尔代夫",
    code: "+960",
  },
  {
    city: "马里",
    code: "+223",
  },
  {
    city: "马耳他",
    code: "+356",
  },
  {
    city: "马提尼克",
    code: "+596",
  },
  {
    city: "毛里塔尼亚",
    code: "+222",
  },
  {
    city: "毛里求斯",
    code: "+230",
  },
  {
    city: "马约特",
    code: "+262",
  },
  {
    city: "墨西哥",
    code: "+52",
  },
  {
    city: "摩尔多瓦",
    code: "+373",
  },
  {
    city: "摩纳哥",
    code: "+377",
  },
  {
    city: "蒙古",
    code: "+976",
  },
  {
    city: "黑山",
    code: "+382",
  },
  {
    city: "蒙特塞拉特",
    code: "+1664",
  },
  {
    city: "摩洛哥",
    code: "+212",
  },
  {
    city: "莫桑比克",
    code: "+258",
  },
  {
    city: "纳米比亚",
    code: "+264",
  },
  {
    city: "尼泊尔",
    code: "+977",
  },
  {
    city: "荷兰",
    code: "+31",
  },
  {
    city: "荷属安的列斯",
    code: "+599",
  },
  {
    city: "新喀里多尼亚",
    code: "+687",
  },
  {
    city: "新西兰",
    code: "+64",
  },
  {
    city: "尼加拉瓜",
    code: "+505",
  },
  {
    city: "尼日尔",
    code: "+227",
  },
  {
    city: "尼日利亚",
    code: "+234",
  },
  {
    city: "挪威",
    code: "+47",
  },
  {
    city: "阿曼",
    code: "+968",
  },
  {
    city: "巴基斯坦",
    code: "+92",
  },
  {
    city: "巴勒斯坦",
    code: "+970",
  },
  {
    city: "巴拿马",
    code: "+507",
  },
  {
    city: "巴布亚新几内亚",
    code: "+675",
  },
  {
    city: "巴拉圭",
    code: "+595",
  },
  {
    city: "秘鲁",
    code: "+51",
  },
  {
    city: "菲律宾",
    code: "+63",
  },
  {
    city: "波兰",
    code: "+48",
  },
  {
    city: "葡萄牙",
    code: "+351",
  },
  {
    city: "波多黎各",
    code: "+1",
  },
  {
    city: "库塔",
    code: "+974",
  },
  {
    city: "留尼汪",
    code: "+262",
  },
  {
    city: "罗马尼亚",
    code: "+40",
  },
  {
    city: "俄罗斯",
    code: "+7",
  },
  {
    city: "卢旺达",
    code: "+250",
  },
  {
    city: "萨摩亚东部",
    code: "+684",
  },
  {
    city: "萨摩亚西部",
    code: "+685",
  },
  {
    city: "圣马力诺",
    code: "+378",
  },
  {
    city: "圣多美和普林西比",
    code: "+239",
  },
  {
    city: "沙特阿拉伯",
    code: "+966",
  },
  {
    city: "塞内加尔",
    code: "+221",
  },
  {
    city: "塞尔维亚",
    code: "+381",
  },
  {
    city: "塞舌尔",
    code: "+248",
  },
  {
    city: "塞拉利昂",
    code: "+232",
  },
  {
    city: "斯洛伐克",
    code: "+421",
  },
  {
    city: "斯洛文尼亚",
    code: "+386",
  },
  {
    city: "南非",
    code: "+27",
  },
  {
    city: "韩国",
    code: "+82",
  },
  {
    city: "西班牙",
    code: "+34",
  },
  {
    city: "斯里兰卡",
    code: "+94",
  },
  {
    city: "圣基茨和尼维斯",
    code: "+1869",
  },
  {
    city: "圣卢西亚",
    code: "+1758",
  },
  {
    city: "圣文森特",
    code: "+1784",
  },
  {
    city: "苏丹",
    code: "+249",
  },
  {
    city: "苏里南",
    code: "+597",
  },
  {
    city: "斯威士兰",
    code: "+268",
  },
  {
    city: "瑞典",
    code: "+46",
  },
  {
    city: "瑞士",
    code: "+41",
  },
  {
    city: "叙利亚",
    code: "+963",
  },
  {
    city: "塔吉克斯坦",
    code: "+992",
  },
  {
    city: "坦桑尼亚",
    code: "+255",
  },
  {
    city: "泰国",
    code: "+66",
  },
  {
    city: "东帝汶",
    code: "+670",
  },
  {
    city: "多哥",
    code: "+228",
  },
  {
    city: "汤加",
    code: "+676",
  },
  {
    city: "特立尼达和多巴哥",
    code: "+1868",
  },
  {
    city: "突尼斯",
    code: "+216",
  },
  {
    city: "土耳其",
    code: "+90",
  },
  {
    city: "土库曼斯坦",
    code: "+993",
  },
  {
    city: "特克斯和凯科斯群岛",
    code: "+1649",
  },
  {
    city: "乌干达",
    code: "+256",
  },
  {
    city: "乌克兰",
    code: "+380",
  },
  {
    city: "阿拉伯联合酋长国",
    code: "+971",
  },
  {
    city: "英国",
    code: "+44",
  },
  {
    city: "美国",
    code: "+1",
  },
  {
    city: "乌拉圭",
    code: "+598",
  },
  {
    city: "乌兹别克斯坦",
    code: "+998",
  },
  {
    city: "瓦努阿图",
    code: "+678",
  },
  {
    city: "委内瑞拉",
    code: "+58",
  },
  {
    city: "越南",
    code: "+84",
  },
  {
    city: "维尔京群岛",
    code: "+1340",
  },
  {
    city: "也门",
    code: "+967",
  },
  {
    city: "赞比亚",
    code: "+260",
  },
  {
    city: "津巴布韦",
    code: "+263",
  },
];

export const PHONE_REG = /[0-9]$/;
export const PHONE_REG1 = /^1[3-9]\d{9}$/;
export const EMAIL_REG =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function toLogin() {
  window.open("https://manage.eclicklink.com", "_blank");
}

export function toRegister() {
  window.open("https://manage.eclicklink.com/affiliate/signup", "_blank");
}
