export default {
  // 正常业务返回
  ok: 200,
  // 提交参数错误
  erparam: 400,
  // 验证码错误
  ercode: 401,
  // 账号被锁定
  lock: 403,
  // 用户不存在
  eruser: 404,
  // 没有权限
  erauth: 405,
  // 非法用户
  out: 406,
  // 系统错误
  sys: 500,
  // 账号名称重复
  acRepeat: 10001,
  // 解绑失败
  unbind: 10002,
}
