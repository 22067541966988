/*
 * 参数说明:
 * @common 后台接收的公共参数,一般是对用户的校验参数
 * @userInfo 用户相关信息
 * @sideopen 菜单的打开状态
 * @poper 全局过滤组件是否打开
 * @routers 经过权限过滤后的路由
 * @actionTypeList 点击菜单时获取到改页面下的actions权限
 * @filterData 生成筛选弹框的数据
 * @res 为从后台请求的响应数据列表(公共状态字段)
 */
export default {
  userInfos: {},
  sideopen: !!+window.localStorage.getItem('barSta'),
  menu: [],
  auth: {},
  actionTypeList: JSON.parse(localStorage.getItem('actionTypeList')) || [],
  actionTypeStatusList: JSON.parse(localStorage.getItem('actionTypeStatusList')) || {},
  lang: window.localStorage.getItem('lang') || 'zh',
  isLogin: false,
  poper: false,
  filterData: {},
  filterUpdata: {},
  menuCode: '',
}
