<template>
  <!--WGCCxxx-->
  <router-view />
</template>

<script>
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  name: "App",
  setup() {
    if (process.env.NODE_ENV === "development") {
      window.addEventListener("message", function (e) {
        if (e.origin === "http://localhost:8080") {
          // localStorage.setItem("token", e.data);
        }
      });
    }
  },
});
</script>
