import { createApp } from "vue";
import App from "./App.vue";
import router from "router";
import Vant from "vant";
import "vant/lib/index.css";
import "styles/index.js";

// 实现移动端的转换
import "amfe-flexible";
import components from "comps/index.js";
import * as tool from "./utils/index";
import store from "store/index.js";

import "xe-utils";
import eventBus from "vue3-eventbus";

// import VideoPlayer from "vue-video-player";
// import "video.js/dist/video-js.css";
// import "vue-video-player/src/custom-theme.css";

const app = createApp(App);
app.config.globalProperties.Tool = tool;

// 全局注册自有组件
Object.keys(components).forEach((key) => {
  const name = key.replace(/(\w)/, (v) => v.toUpperCase()); // 首字母大写
  app.component(`v${name}`, components[key]);
});
app.use(router).use(store).use(Vant).use(eventBus).mount("#app");
