// 封装请求
import rule from "./rule";
import axios from "axios";
import qs from "qs";
import router from "../router";
import store from "../store";
import { setCookie } from "../utils/index";
// import store from '../store'
import { Toast } from "vant";
import localStorageUtil from "@/utils/localStorageUtil";
import cache from "./cache";
const baseUrlMap = new Map([
  ["localhost", "http://172.30.90.191:8071/"],
  ["verify.ymtech.info", "https://verify.ymtech.info/api"],
  ["172.30.90.190", `https://verify.ymtech.info/api`],
  ["172.30.90.190", "http://172.30.90.191:8071/"],
  ["172.30.90.190", "http://192.168.134.124:8071/"],
  // ["172.30.90.190", "https://verify.ymtech.info/api"],
]);

//如果有上面的几个走上面几个，没有的话走默认，可扩展性强一些
const rootbBaseUrl = baseUrlMap.get(location.hostname)
  ? baseUrlMap.get(location.hostname)
  : "https://verify.ymtech.info/api";

// 全局设置
axios.defaults.withCredentials = true;
const errHandler = () => {
  Toast.fail(lang[localStorage.getItem("lang") || "zh"].error);
};
// 服务器错误信息国际化
const lang = {
  en: {
    error: "The server responded incorrectly, please try again later",
    acRepeat: "This binding relationship already exists",
    unbind: "Unbind failed! Until you unbind the oﬀer and try again!",
  },
  zh: {
    error: "服务器未响应，请稍后重试",
    acRepeat: "此绑定关系已存在",
    unbind: "解绑失败！请先解除与oﬀer绑定关系再试！",
  },
  ja: {
    error: "サーバーが応答していません。しばらくしてからもう一度お試しください",
    acRepeat: "この結合関係は既に存在します",
    unbind:
      "失敗するまで！ まず案件との関係を解き、もう一度やり直してください。",
  },
};

export default class BaseRequest {
  constructor(baseURL) {
    this.http = axios.create({
      baseURL: rootbBaseUrl + baseURL,
      adapter: cache({
        time: 1000,
      }),
    });

    // 全局默认配置
    this.http.defaults.headers["Authorization"] =
      localStorage.getItem("ADMIN-TOKEN") || "";
    // 请求拦截器
    this.http.interceptors.request.use(
      (config) => {
        // config.headers["token"] = localStorageUtil.get("token") || ""; // localStorage.getItem('token') || ''
        // config.headers["menuCode"] = store.state.menuCode || "";
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // 响应拦截器
    this.http.interceptors.response.use(
      (response) => {
        //这里为啥不直接写成response.data.data，是留个口子，万一后面有扩展
        const data = response.data || "";
        const msg = response.data.msg || "";
        switch (response.status) {
          case rule.ok:
            return Promise.resolve(data);
            break;
          case rule.ercode:
            Toast.fail(msg);
            return Promise.resolve({
              type: "vcode",
              result: response.data,
            });
          case rule.out:
            window.location.reload();
            break;
          case rule.erauth:
            setCookie("remember", "", -1);
            router.push({ path: "/login" });
            break;
          case rule.acRepeat:
            return Promise.resolve(data);
          case rule.unbind:
            return Promise.resolve(data);
          default:
            Toast.fail(msg);
            return Promise.resolve(data);
        }
      },
      (error) => {
        errHandler();
        return Promise.reject(error);
      }
    );
  }
  request({ url, method, params, type }) {
    if (method === "get") {
      return this.get(url, params);
    } else {
      if (type === "query") {
        return this.postForm(url, params);
      } else {
        return this.post(url, params);
      }
    }
  }
  post(url, params = {}, config = {}) {
    return this.http.post(url, params, config);
  }
  postForm(url, params = {}, config = {}) {
    return this.http({
      url,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      ...config,
      data: qs.stringify(params),
    });
  }
  get(url, params = {}, config = {}) {
    let _config = {
      ...config,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          arrayFormat: "repeat",
          encode: true,
        });
      },
      data: {},
    };
    _config = Object.assign(_config, config) || {};
    return this.http.get(url, _config);
  }
  postMul(url, params, config) {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      // 为null时 formData会自动转为字符串'null'，所以中间做这层处理
      formData.append(key, params[key] === null ? "" : params[key]);
    });
    return this.http({
      url,
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      ...config,
    });
  }
}
