<template>
  <van-empty description="暂无数据" />
</template>

<script>
import { defineComponent, toRefs, reactive } from "vue";
export default defineComponent({
  name: "NoData",
  props: {},
});
</script>

<style></style>
