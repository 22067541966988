<template>
  <header class="g-header">
    <div class="cont-search">
      <van-search
        v-model="oinfo"
        shape="round"
        clearable
        :placeholder="content"
        @input="change"
        @search="onSearch"
        @clear="clearHandle"
      />
    </div>
    <div class="g-header-right">
      <span class="iconfont iconfilter"></span>
      <p class="higher-search" @click="openDialog">高级查询</p>
    </div>
    <upNowGo />
  </header>
</template>

<script>
import { defineComponent, ref, toRefs, watch } from "vue";
import upNowGo from "@/components/upNow/index.vue";
export default defineComponent({
  components: {
    upNowGo,
  },
  props: {
    content: String,
    info: String,
  },
  emits: ["open", "update:info", "on-search", "on-clear"],
  setup(props, context) {
    const oinfo = ref(props.info);
    watch(
      () => props.info,
      () => {
        oinfo.value = props.info;
      }
    );
    function openDialog() {
      context.emit("open");
    }
    function change(e) {
      context.emit("update:info", e.target.value);
    }
    function onSearch(value) {
      context.emit("update:info", value);
      context.emit("on-search");
    }
    function clearHandle(value) {
      context.emit("update:info", "");
      context.emit("on-search");
    }
    return {
      oinfo,
      onSearch,
      openDialog,
      change,
      clearHandle,
    };
  },
});
</script>

<style lang="less" scoped></style>
