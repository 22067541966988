import { objectMerge } from "@/utils";
export default {
  // 用户登出
  mLogout(state) {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("menuCode");
    window.localStorage.removeItem("menuList");
    window.localStorage.removeItem("userName");
    window.localStorage.removeItem("name");
    window.localStorage.removeItem("actionTypeList");
    window.localStorage.removeItem("actionTypeStatusList");
    state.userInfos = {};
    state.auth = {};
    state.menu = [];
    state.isLogin = false;
  },
  mFilterUpdata(state, data) {
    state.filterUpdata = JSON.parse(JSON.stringify(data));
  },
  mMenuCode(state, data) {
    state.menuCode = data;
  },
  // 存储当前页面的actionTypeList信息
  mActionTypeList(state, data) {
    state.actionTypeList = JSON.parse(JSON.stringify(data));
  },
  mActionTypeStatusList(state, data) {
    const actionTypeList = data;
    state.actionTypeStatusList = {};
    if (actionTypeList && actionTypeList.length > 0) {
      actionTypeList.forEach((item) => {
        state.actionTypeStatusList[AuthorityActions[item]] = true;
      });
    }
  },
};
