<template>
  <div id="upBtn" class="upNowIcon" :class="{ hide: isHide }" @click="goUp">
    <v-icon icon-class="upGo" />
  </div>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  watchEffect,
  toRefs,
  toRef,
  ref,
  onBeforeUnmount,
} from "vue";
import { claimTypeOptions } from "@/hooks/useDictionary";
export default defineComponent({
  props: {
    status: String,
  },
  setup(props, { emit }) {
    const { status } = toRefs(props);
    const state = reactive({
      isHide: true,
      status,
      clientHeight: 0,
    });

    function goUp() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

    onMounted(() => {
      state.clientHeight = document.documentElement.clientHeight;
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    function handleScroll() {
      if (document.documentElement.scrollTop > state.clientHeight) {
        state.isHide = false;
      } else {
        state.isHide = true;
      }
    }

    return {
      ...toRefs(state),
      goUp,
    };
  },
});
</script>

<style></style>
