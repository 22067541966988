import BaseRequest from "@/http/newRequest.js"
const api = new BaseRequest('')
export function queryUserActionListByMenuId(params) {
  return api.request({
    url: 'authority/queryUserActionListByMenuCode',
    method: 'post',
    params,
    type: 'query'
  })
}
export function getUserToken(params) {
  return api.request({
    url: 'user/auth',
    method: 'get',
    params,
    type: 'query'
  })
}
export function getMenuList(params) {
  return api.request({
    url: 'authority/queryMenuListBySystemAccount',
    method: 'post',
    params,
    type: 'query',
  })
}
